@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "Albert Sans";
    src: url("/fonts/AlbertSans-Regular.eot");
    src: url("/fonts/AlbertSans-Regular.eot?#iefix") format("embedded-opentype"),
      url("/fonts/AlbertSans-Regular.woff2") format("woff2"),
      url("/fonts/AlbertSans-Regular.woff") format("woff"),
      url("/fonts/AlbertSans-Regular.svg#AlbertSans-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Albert Sans";
    src: url("/fonts/AlbertSans-SemiBold.eot");
    src: url("/fonts/AlbertSans-SemiBold.eot?#iefix")
        format("embedded-opentype"),
      url("/fonts/AlbertSans-SemiBold.woff2") format("woff2"),
      url("/fonts/AlbertSans-SemiBold.woff") format("woff"),
      url("/fonts/AlbertSans-SemiBold.svg#AlbertSans-SemiBold") format("svg");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Albert Sans";
    src: url("/fonts/AlbertSans-Bold.eot");
    src: url("/fonts/AlbertSans-Bold.eot?#iefix") format("embedded-opentype"),
      url("/fonts/AlbertSans-Bold.woff2") format("woff2"),
      url("/fonts/AlbertSans-Bold.woff") format("woff"),
      url("/fonts/AlbertSans-Bold.svg#AlbertSans-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Montserrat";
    src: url("/fonts/Montserrat-Light.eot");
    src: url("/fonts/Montserrat-Light.eot?#iefix") format("embedded-opentype"),
      url("/fonts/Montserrat-Light.woff2") format("woff2"),
      url("/fonts/Montserrat-Light.woff") format("woff"),
      url("/fonts/Montserrat-Light.svg#Montserrat-Light") format("svg");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Montserrat";
    src: url("/fonts/Montserrat-Regular.eot");
    src: url("/fonts/Montserrat-Regular.eot?#iefix") format("embedded-opentype"),
      url("/fonts/Montserrat-Regular.woff2") format("woff2"),
      url("/fonts/Montserrat-Regular.woff") format("woff"),
      url("/fonts/Montserrat-Regular.svg#Montserrat-Regular") format("svg");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Montserrat";
    src: url("/fonts/Montserrat-Medium.eot");
    src: url("/fonts/Montserrat-Medium.eot?#iefix") format("embedded-opentype"),
      url("/fonts/Montserrat-Medium.woff2") format("woff2"),
      url("/fonts/Montserrat-Medium.woff") format("woff"),
      url("/fonts/Montserrat-Medium.svg#Montserrat-Medium") format("svg");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Montserrat";
    src: url("/fonts/Montserrat-Bold.eot");
    src: url("/fonts/Montserrat-Bold.eot?#iefix") format("embedded-opentype"),
      url("/fonts/Montserrat-Bold.woff2") format("woff2"),
      url("/fonts/Montserrat-Bold.woff") format("woff"),
      url("/fonts/Montserrat-Bold.svg#Montserrat-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: "Merriweather";
    src: url("/fonts/Merriweather-Bold.eot");
    src: local("/fonts/Merriweather Bold"), local("Merriweather-Bold"),
      url("/fonts/Merriweather-Bold.eot?#iefix") format("embedded-opentype"),
      url("/fonts/Merriweather-Bold.woff2") format("woff2"),
      url("/fonts/Merriweather-Bold.woff") format("woff"),
      url("/fonts/Merriweather-Bold.ttf") format("truetype"),
      url("/fonts/Merriweather-Bold.svg#Merriweather-Bold") format("svg");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  html {
    @apply scroll-smooth !h-full;
  }

  body {
    @apply font-albert font-normal text-xl text-black overflow-x-hidden
        !flex !h-full !flex-col;
  }

  body.hidden-scrolling {
    @apply overflow-y-hidden;
  }

  a {
    @apply text-link-color underline transition-all duration-300;
  }

  a:hover {
    @apply no-underline;
  }

  p {
    @apply pb-[1em];
  }

  h1 {
    @apply font-merriweather font-normal md:text-[30px] text-[28px] leading-[38px];
  }

  h2 {
    @apply font-merriweather font-bold text-[24px] leading-[31px];
  }

  h3 {
    @apply font-merriweather font-bold;
  }

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    @apply mb-2.5 text-black;
  }
  ul,
  ol {
    @apply list-none m-0 p-0;
  }
}

/*======== Header ========*/

.header {
  @apply w-full shadow-c py-0 z-50 relative;
}

.header.is-sticky {
  background: #fff;
  position: fixed;
  box-shadow: 0 5px 16px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  animation: slideDown 0.35s ease-out;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

.header .main-menu ul {
  @apply flex flex-wrap items-center justify-center;
}

.header .main-menu ul li {
  @apply mx-4;
}

.header .main-menu ul li a {
  @apply py-[30px] block;
}

.header.is-sticky .main-menu ul li a {
  @apply py-6;
}

.header .main-menu ul li:hover > a,
.header .main-menu ul li a.active {
  @apply text-link-color;
}

.header .main-menu ul li a.active:before,
.header .main-menu ul li a:hover:before,
.header .main-menu ul li:hover > a:before {
  @apply content-[""] border-b-4 border-link-hover w-full absolute left-0 -bottom-0 block;
}

.header .main-menu ul li .sub-menu {
  @apply block;
}

.header .main-menu ul li .sub-menu li {
  @apply px-0;
}

.header .main-menu ul li .sub-menu li a {
  @apply px-0 py-1.5;
}

.header .main-menu ul li .sub-menu li a:before {
  @apply hidden;
}

.header .main-menu ul li.has-children:hover .sub-menu {
  @apply opacity-100 visible mt-0;
}

.header .social-link {
  @apply hidden sm:flex flex-wrap items-center justify-center;
}

.header .social-link li {
  @apply px-1.5;
}

.social-link li a {
  @apply w-[22px] h-[18px] text-[0] block;
}

.social-link li.facebook a {
  @apply bg-[url('/images/ic-facebook.svg')] bg-no-repeat bg-center;
}

.social-link li.facebook a:hover {
  @apply bg-[url('/images/ic-facebook-hover.svg')] bg-no-repeat bg-center;
}
.social-link li.twitter a {
  @apply bg-[url('/images/ic-twitter.svg')] bg-no-repeat bg-center;
}

.social-link li.twitter a:hover {
  @apply bg-[url('/images/ic-twitter-hover.svg')] bg-no-repeat bg-center;
}
.social-link li.instagram a {
  @apply bg-[url('/images/instagram.svg')] bg-no-repeat bg-center;
}

.social-link li.instagram a:hover {
  @apply bg-[url('/images/instagram-hover.svg')] bg-no-repeat bg-center;
}
.social-link li.youtube a {
  @apply bg-[url('/images/youtube.svg')] bg-no-repeat bg-center;
}

.social-link li.youtube a:hover {
  @apply bg-[url('/images/youtube-hover.svg')] bg-no-repeat bg-center;
}

.header .search-form {
  @apply ml-4 w-[172px];
}

.header .search-form form {
  @apply relative;
}

.header .search-form input[type="text"] {
  @apply text-[13px] border border-[#707070]/50 rounded-md pl-2.5 pr-8 h-8 py-0 leading-[30px] w-full shadow-none outline-none;
}

.header .search-form button {
  @apply bg-[url('/images/search-btn.svg')] bg-no-repeat absolute right-2 top-1/2 -translate-y-1/2 w-3 h-[14px] text-[0];
}

/*======== Article Card ========*/

.articleCard-group .articleCard-item {
  @apply w-full sm:w-[calc(50%-10px)] sm:mr-2.5 md:mr-2.5 ml-0 md:ml-0 mb-5;
}

.articleCard-group .articleCard-box:nth-child(1) {
  @apply pl-0 md:pr-2.5;
}

.articleCard-group .articleCard-box:nth-child(1) .articleCard-item {
  @apply w-full mr-0;
}

.articleCard-group
  .articleCard-box:nth-child(2)
  .articleCard-item:nth-child(even) {
  @apply mr-0 sm:ml-2.5;
}

.articleCard-item .articleCard-text {
  @apply h-full pt-[calc(56%_+_16px)] -mt-[56%] flex flex-col justify-between bg-white rounded-t-2xl rounded-b-[14px];
}

.articleCard-item .articleCard-text h3 {
  @apply font-albert font-semibold text-base leading-5 text-black;
}

.articleCard-item .articleCard-text h3 a {
  @apply font-albert font-semibold text-base leading-5 text-black no-underline line-clamp-3 tracking-tight;
}

.articleCard-item .articleCard-text h3 a:hover {
  @apply text-link-color;
}

.articleCard-box:nth-child(1) .articleCard-item .articleCard-text h3 a {
  @apply font-albert text-2xl leading-[30px] font-bold tracking-normal;
}

.articleCard-group .articleCard-box {
  @apply flex flex-wrap md:pl-2.5;
}

.articleCard-group .articleCard-box.horizontal-items {
  @apply content-start;
}

.articleCard-group .articleCard-box.horizontal-items .articleCard-item {
  @apply w-full flex md:flex-row flex-col !ml-0 mr-0;
}

.articleCard-group
  .articleCard-box.horizontal-items
  .articleCard-item
  .articleCard-img {
  @apply md:w-[210px] w-full h-[170px] pt-0;
}

.articleCard-group
  .articleCard-box.horizontal-items
  .articleCard-item
  .articleCard-img
  img {
  @apply rounded-2xl md:absolute relative;
}

.articleCard-group
  .articleCard-box.horizontal-items
  .articleCard-item
  .articleCard-text {
  @apply w-full md:w-[calc(100%_-_210px)] pt-[0.5rem] m-0;
}

.articleCard-box-inner {
  @apply flex flex-wrap gap-4 mb-12;
}

.articleCard-box-inner .articleCard-item {
  @apply w-full sm:w-[calc(50%_-_12px)] md:w-[calc(25%_-_12px)];
}

.articleCard-box-inner .articleCard-item .articleCard-text h3 a {
  @apply sm:line-clamp-2;
}

.articleCard-box-inner .articleCard-item .articleCard-text .short-info {
  @apply line-clamp-3 sm:line-clamp-2 text-base mb-5;
}

.contentCol .articleCard-item {
  @apply sm:flex mb-5 rounded-2xl;
}

.contentCol .articleCard-item .articleCard-img {
  @apply md:w-[210px] w-full min-h-[170px] pt-0 mx-auto sm:mx-0;
}

.contentCol .articleCard-item .articleCard-img img {
  @apply rounded-2xl;
}

.contentCol .articleCard-item .articleCard-text {
  @apply sm:w-[calc(100%_-_210px)] pt-4 px-5 sm:pt-2 pb-3 m-0 h-auto;
}

.contentCol .articleCard-item .articleCard-text h3 {
  @apply mb-3;
}

.contentCol .articleCard-item .articleCard-text h3 a {
  @apply line-clamp-2 text-lg;
}

.contentCol .articleCard-item .articleCard-text .short-info {
  @apply text-base;
}

/*======== Most Popular ========*/

.most-popular {
  background: #ffe1ac;
  background: linear-gradient(45deg, #ffe1ac, #ffe0e8);
}

.most-popular .emoji-block ul li:nth-child(1) a {
  @apply rounded-t-[10px];
}

.most-popular .emoji-block ul li:nth-last-child(1) a {
  @apply rounded-b-[10px];
}

/*======== Trending stories ========*/
.tooltip {
  @apply relative inline-block text-center;
}

.tooltip a {
  @apply w-6 h-6 block;
}

.tooltip a img {
  @apply w-6 h-6 rounded-full object-cover;
}

.tooltip .top {
  @apply min-w-max -top-3 left-1/2 -translate-x-1/2 -translate-y-full px-2.5 py-0 text-white 
    bg-black font-normal text-[13px] rounded-lg absolute z-50 hidden;
}

.tooltip:hover .top {
  @apply block;
}

.tooltip .top i {
  position: absolute;
  top: 100%;
  left: 50%;
  margin-left: -12px;
  width: 24px;
  height: 12px;
  overflow: hidden;
}

.tooltip .top i::after {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  left: 50%;
  transform: translate(-50%, -50%) rotate(45deg);
  background-color: #000000;
}

.rightCol ul.topics-list {
  @apply justify-start mb-8;
}

ul.topics-list li a {
  @apply px-6;
}

ul.topics-list li a.active {
  @apply bg-link-hover font-bold text-white;
}

/*======== your Meme ========*/

.your-meme {
  background: #ffe1ac;
  background: linear-gradient(45deg, #ffe1ac, #ffe0e8);
}

/*======== Contact us page ========*/

.contact-form label {
  @apply block text-base text-black mb-2.5;
}

.contact-form label span {
  @apply text-[#FF0000];
}

.contact-form input {
  @apply border border-[#E2E2E2] rounded h-11 px-3 py-0 w-full;
}

.contact-form input:focus {
  @apply border-black;
}

.contact-form textarea {
  @apply w-full h-[100px] border border-[#E2E2E2] rounded p-3 resize-none;
}

.contact-form .btn-submit {
  @apply bg-link-color w-36 h-12 rounded-lg text-white font-semibold uppercase;
}

.contact-form .btn-submit:hover {
  @apply bg-link-hover;
}

/*======== Footer ========*/

.footer {
  @apply mt-auto;
}

.footer .footer-menu a.active {
  @apply text-link-hover underline;
}

.footer .newsletter input[type="email"] {
  @apply border-2 border-[#CBCBCB] rounded-[10px] px-2.5 py-0 font-albert text-sm w-full h-12 outline-none mb-3;
}

.footer .newsletter button {
  @apply bg-link-hover w-full text-center font-albert font-bold text-base uppercase text-white h-12 
    rounded-[10px] hover:bg-link-hover/90;
}

.breadcrumb li {
  @apply relative;
}

.inner-banner .breadcrumb li::before {
  @apply bg-black/50;
}

.breadcrumb li::before {
  @apply bg-black/50 w-[5px] h-[5px] content-[""] block rounded-full absolute -left-[14px] top-[11px];
}

.breadcrumb li:first-child:before {
  @apply hidden;
}

.main-article .author,
.inner-banner .author {
  @apply flex flex-wrap space-x-4;
}

.main-article .author li::before {
  @apply hidden;
}

.main-article .author li a {
  @apply flex font-albert font-normal text-base text-black no-underline hover:text-link-color items-center;
}

.inner-banner .author li a {
  @apply flex font-albert font-normal text-base text-white no-underline hover:underline items-center;
}

.main-article .author li a img,
.inner-banner .author li a img {
  @apply w-5 h-5 object-cover rounded-full mr-1.5;
}

.main-article .author li {
  @apply flex font-albert font-normal text-base text-black relative pl-0;
}

.inner-banner .author li {
  @apply flex font-albert font-normal text-base text-white relative;
}

.main-article .author li:nth-last-child(1),
.inner-banner .author li:nth-last-child(1) {
  @apply !ml-6;
}

.main-article .author li:nth-last-child(1)::before {
  @apply bg-black content-[""] w-px h-3 -left-3 top-1.5 absolute block;
}

.inner-banner .author li:nth-last-child(1)::before {
  @apply bg-white content-[""] w-px h-3 -left-3 top-1.5 absolute block;
}

.main-article .social-tag-list li {
  @apply pl-0;
}

.main-article .social-tag-list li::before {
  @apply hidden;
}

.main-article .social-tag-list li a:link,
.main-article .social-tag-list li a:active,
.main-article .social-tag-list li a:visited {
  @apply hover:font-semibold;
}

.main-article .social-tag-list li a.active {
  @apply font-bold bg-black text-white border-black;
}

.inner-banner .social-tag-list li a:link,
.inner-banner .social-tag-list li a:active,
.inner-banner .social-tag-list li a:visited {
  @apply hover:font-semibold;
}

.inner-banner .social-tag-list li a.active {
  @apply font-bold bg-white text-black border-white;
}

.main-article .left-side-content-menu {
  @apply mt-5 mb-[30px] max-w-[500px];
}

.main-article .left-side-content-menu ol li {
  @apply p-0 mb-4 relative leading-none;
}

.main-article .left-side-content-menu ol li:nth-last-child(1) {
  @apply mb-0;
}

.main-article .left-side-content-menu ol li::before {
  @apply hidden;
}

.main-article .left-side-content-menu ol li.active::after,
.main-article .left-side-content-menu ol li:hover::after {
  @apply bg-link-hover content-[""] w-[3px] min-h-[36px] h-[110%] block absolute -left-6 top-1/2 -translate-y-1/2;
}

.main-article .left-side-content-menu ol li.active a {
  @apply text-link-hover underline;
}

.main-article .social-wrap ul li {
  @apply p-0 pr-1.5;
}

.main-article .social-wrap ul li::before {
  @apply hidden;
}

.main-article .social-wrap li a {
  @apply w-[34px] h-[34px] text-[0] block;
}

.main-article .social-wrap li.facebook button {
  @apply bg-[url('/images/facebook.svg')] bg-no-repeat bg-center;
}

.main-article .social-wrap li.facebook button:hover {
  @apply bg-[url('/images/facebook-hover.svg')] bg-no-repeat bg-center;
}

.main-article .social-wrap li.twitter button {
  @apply bg-[url('/images/twitter.svg')] bg-no-repeat bg-center;
}

.main-article .social-wrap li.twitter button:hover {
  @apply bg-[url('/images/twitter-hover.svg')] bg-no-repeat bg-center;
}

.main-article .social-wrap li.reddit button {
  @apply bg-[url('/images/reddit.svg')] bg-no-repeat bg-center;
}

.main-article .social-wrap li.reddit button:hover {
  @apply bg-[url('/images/reddit-hover.svg')] bg-no-repeat bg-center;
}

.main-article .social-wrap li.linkedin button {
  @apply bg-[url('/images/linkedin.svg')] bg-no-repeat bg-center;
}

.main-article .social-wrap li.linkedin button:hover {
  @apply bg-[url('/images/linkedin-hover.svg')] bg-no-repeat bg-center;
}

.main-article .social-wrap li.mail button {
  @apply bg-[url('/images/mail.svg')] bg-no-repeat bg-center;
}

.main-article .social-wrap li.mail button:hover {
  @apply bg-[url('/images/mail-hover.svg')] bg-no-repeat bg-center;
}

.main-article .contentCol ::-moz-selection {
  @apply bg-[#FFCD78];
}

.main-article .contentCol ::selection {
  @apply bg-[#FFCD78];
}

.main-article blockquote {
  @apply bg-[#E05D5D] px-6 py-4 text-white mb-[1em];
}

.main-article blockquote a {
  @apply text-white;
}

.main-article blockquote p {
  margin: 0px;
  padding: 0px;
}

.main-article h6 {
  @apply text-[13px] -mt-12 text-[#888888] mb-4;
}

.main-article ul li {
  @apply relative pl-5;
}

.main-article .rightCol ul {
  @apply pl-5;
}

.main-article .rightCol ul li {
  @apply pl-0;
}

.main-article .rightCol ul li::before {
  @apply hidden;
}

.main-article ul li::before {
  @apply bg-link-hover w-2 h-2 content-[""] block rounded-full absolute left-0 top-[9px];
}

.main-article .rightCol ul li::marker {
  @apply font-albert font-normal text-base;
}

.main-article .rightCol .articleCard-item {
  @apply flex shadow-none border-b border-[#707070]/30 pb-7 mb-6 rounded-none;
}

.main-article .rightCol .articleCard-item:nth-last-child(1) {
  @apply pb-0 border-none;
}

.main-article .rightCol .articleCard-item .articleCard-img {
  @apply p-0;
}

.main-article .rightCol .articleCard-item .articleCard-img img {
  @apply static w-[100px] h-[76px] object-cover rounded-[10px];
}

.main-article .rightCol .articleCard-item .articleCard-text {
  @apply px-5 py-0 m-0 w-[calc(100%_-_100px)] block;
}

.main-article .rightCol .articleCard-item .articleCard-text .short-info {
  @apply hidden;
}

.main-article .rightCol .articleCard-item .articleCard-text h3 a {
  @apply line-clamp-2;
}

.details-section {
  width: 100%;
}
.details-section figure.media {
  @apply w-full my-5;
}

.details-section iframe {
  @apply !w-full aspect-video;
}

.details-section img {
  @apply !w-full;
}

.details-section table {
  @apply w-full mb-10;
}

.details-section table p {
  margin: 0px;
  padding: 0px;
}

.details-section table tbody {
  @apply w-full;
}

.details-section table tr th {
  @apply border-gray-100 border p-2 text-center bg-[#f3f3f3];
}

.details-section table tr td {
  @apply border-gray-100 border px-5 py-2;
}

.details-section pre {
  @apply bg-[#FED8B1] my-5;
}

.details-section pre code {
  @apply w-full p-5 block text-base;
}

.details-section ol li p {
  @apply inline;
}

.responsive-iframe {
  @apply relative pb-[56%] h-0 overflow-hidden w-full mb-10;
}

.responsive-iframe iframe {
  @apply absolute left-0 top-0 w-full h-full;
}

.comment-list .comment-box {
  @apply mb-8;
}

.comment-list .comment-box .text-box {
  @apply w-[calc(100%_-_110px)];
}

.table-of-content ul li {
  @apply pl-0;
}

.table-of-content ul li::before {
  @apply hidden;
}

.table-of-content ul li a {
  @apply no-underline block;
}

.table-of-content ul li .item-img {
  @apply w-48 h-48 mb-4 rounded-full transition-all duration-200 mx-auto;
}

.table-of-content ul li a:hover .item-title {
  @apply text-link-color;
}

.table-of-content ul li .item-img img {
  @apply w-full h-full object-cover rounded-full;
}

.bg-title-box {
  @apply py-12 md:py-[76px] w-screen relative left-1/2 right-1/2 -ml-[50vw] -mr-[50vw] md:mb-16 mb-10 px-4 md:px-0;
}

.bg-title-box h2 {
  @apply font-merriweather font-bold md:text-[32px] md:leading-[42px] text-center text-white 
    border-b border-white/50 pb-6 max-w-[700px] w-full mx-auto mb-0;
}

.sitemap-top-list a.active {
  @apply text-link-hover underline;
}

.sitemap-input-search input {
  @apply border border-[#707070]/50 rounded-lg w-full px-5 py-0 h-12
    font-albert font-light text-base text-black/50;
}

.sitemap-block {
  @apply border-b border-[#707070]/50 mb-10 pb-10;
}

.sitemap-block:nth-last-child(1) {
  @apply border-none;
}

.sitemap-block ul {
  @apply flex flex-wrap;
}

.sitemap-block ul li {
  @apply w-full mb-3;
}

.sitemap-block ul li a {
  @apply font-albert font-medium text-base text-black no-underline hover:text-link-hover;
}

.inner-banner-author .breadcrumb li::before {
  @apply bg-white/50;
}

.inner-banner-author .breadcrumb li,
.inner-banner-author .breadcrumb li a {
  @apply text-white;
}

.inner-banner-author .author-info .social-links {
  @apply bg-[#187FB9] rounded-md text-white px-2 py-2 font-albert text-sm no-underline 
    inline-block shadow-[0_3px_6px_0_rgba(0,0,0,0.16)] hover:bg-[#187FB9]/70;
}

.inner-banner-author .author-info .social-links a {
  @apply flex no-underline ml-1 text-white;
}

.inner-banner-author .author-info .social-links a span {
  @apply ml-[5px] block;
}

.inner-banner-author .author-info .social-links .linkedin {
  @apply bg-[url('/images/linkedin-logo.png')] bg-no-repeat bg-center w-[60px];
}

.inner-banner-author .author-info .social-links .facebook {
  @apply bg-[url('/images/ic-facebook.svg')] bg-no-repeat bg-center w-[18px];
}

.inner-banner-author .author-info .social-links .twitter {
  @apply bg-[url('/images/ic-twitter.svg')] bg-no-repeat bg-center w-[22px];
}

.inner-banner-author .author-info .social-links .instagram {
  @apply bg-[url('/images/instagram.svg')] bg-no-repeat bg-center w-[22px];
}

.inner-banner-author .author-info .social-links .youtube {
  @apply bg-[url('/images/youtube.svg')] bg-no-repeat bg-center w-[22px];
}

.custom-clamp-1 {
  @apply line-clamp-1;
}

.custom-clamp-2 {
  @apply line-clamp-2;
}

.custom-clamp-3 {
  @apply line-clamp-3;
}

.custom-clamp-4 {
  @apply line-clamp-4;
}

.custom-clamp-5 {
  @apply line-clamp-5;
}
/*======== Media CSS ========*/

@media (min-width: 640px) {
  .sitemap-block ul li {
    @apply w-1/2 pr-2.5 even:pr-0;
  }
}

@media (min-width: 1400px) {
  .main-article .left-side-content-menu {
    @apply w-[300px] -ml-[300px] mt-0 pr-5;
  }
  .main-article .left-side-content-menu ol {
    @apply pl-10;
  }
}

@media (min-width: 1660px) {
  .main-article .left-side-content-menu {
    @apply w-[320px] -ml-[320px] pr-10;
  }
}

@media (max-width: 1023px) {
  .header {
    @apply py-4;
  }
  .header .main-menu {
    @apply hidden;
  }
  .responsive-menu {
    @apply block;
  }
  .responsive-menu .main-menu {
    @apply fixed -right-72 w-72 h-full overflow-y-auto bg-white z-[9999] top-0
        transition-all duration-300 invisible;
  }
  .responsive-menu .main-menu ul {
    @apply block p-4;
  }
  .responsive-menu .main-menu ul li {
    @apply m-0;
  }
  .responsive-menu .main-menu ul li a {
    @apply text-black py-3 block;
  }
  .responsive-menu .main-menu ul li a:hover,
  .responsive-menu .main-menu ul li a.active {
    @apply text-link-color;
  }
  .responsive-menu .main-menu ul li .sub-menu {
    @apply opacity-100 visible static w-full rounded-none shadow-none py-0 m-0 bg-transparent;
  }
  .responsive-menu .main-menu.open {
    @apply visible right-0;
  }
  .header .open-nav-menu {
    @apply flex;
  }
  .responsive-menu .close-nav-menu {
    @apply flex;
  }
  .responsive-menu .right-box {
    @apply w-auto;
  }
  .responsive-menu .menu-overlay.active {
    @apply visible opacity-100;
  }
}
